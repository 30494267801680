var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    _vm._b(
      {
        directives: [
          {
            name: "loadmore",
            rawName: "v-loadmore",
            value: _vm.loadMore,
            expression: "loadMore",
          },
        ],
        attrs: {
          placeholder: "请选择",
          filterable: "",
          clearable: "",
          remote: "",
          height: "auto",
          loading: _vm.loading,
          "remote-method": _vm.remoteMethod,
        },
        on: {
          change: _vm.handleValueChange,
          "visible-change": _vm.handleVisible,
          clear: _vm.handleClearSelected,
        },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      },
      "el-select",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.optionsSelect, function (item) {
      return _c(
        "el-option",
        {
          key: item[_vm.option.code],
          attrs: { label: item[_vm.option.name], value: item[_vm.option.code] },
        },
        [_vm._t("default", null, { row: item })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }